<template>
  <v-app class="w-100 ma-0 pa-0">
    <header>
      <NavMenu @open-intro-dialog="onIntroDialogStateChange"></NavMenu>
    </header>
    <v-main class="w-100">
      <v-sheet>
        <v-container :class="$vuetify.display.mobile ? `pa-2` : `pa-4`" class="w-100">
          <router-view></router-view>
        </v-container>
      </v-sheet>
    </v-main>
    <v-footer></v-footer>
    <IntroDialog :showdialog="showDialog && !showConsentDialog" @dialog-change="onDialogChange"></IntroDialog>
    <ConsentComponent></ConsentComponent>
  </v-app>
</template>

<script>
import { defineComponent } from "vue";
import NavMenu from "./components/NavMenu.vue";
import IntroDialog from "./components/IntroDialog.vue";
import ConsentComponent from "./components/ConsentComponent.vue";
import { useAuthStore } from "./stores/AuthStore";
import { useConsentStore } from "./stores/ConsentStore";

export default defineComponent({
  name: 'App',
  components: {
    NavMenu,
    IntroDialog,
    ConsentComponent,
  },
  created() {
    document.title = "Gloria Victis 2024";
  },
  data() {
    return {
      consentStore: useConsentStore(),
      authStore: useAuthStore(),
      showDialog: null,
      showConsentDialog: false,
    };
  },
  mounted() {
    this.showConsentDialog = !this.authStore.hasConsentGranted;
  },
  methods: {
    onIntroDialogStateChange(value) {
      this.showDialog = value;
    },
    onDialogChange(value) {
      this.showDialog = value;
    },
    onConsentDialogChange(value) {
      this.authStore.grantConsent(value.consent);
    },
  },
})
</script>

<style></style>
