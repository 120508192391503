<template>
    <v-card class="py-4" :class="$vuetify.display.mobile ? `px-0` : `px6`">
        <v-card-title class="text-h4 text-end mb-4">INFORMÁCIÓK, KAPCSOLAT</v-card-title>
        <v-card-text class="text-start">
            <section class="my-2">
                <h5 class="text-h5 mb-1 mt-6">A verseny lebonyolítása</h5>
                <p>
                    A Rákóczi Szövetség 2024-ban 29. alkalommal hirdeti meg
                    Kárpát-medencei középiskolások számára a Gloria Victis történelmi
                    vetélkedőt, amelynek idei témája:<b> Forradalom és szabadságharc (1848-1849)</b>
                </p>
                <p>
                    A három fordulóból álló vetélkedő meghirdetése minden évben tavasszal történik. A háromfős csapatok
                    egy tesztsor kitöltésével nevezhetnek. A beküldött dolgozatok alapján a legjobb 60 csapat meghívást
                    kap a második fordulóra, amely részét képezi a Gloria Victis 1956-os Ifjúsági Találkozónak
                    Budapesten október 21. és 23. között.</p>
                <p>
                    A vetélkedő középdöntője és döntője a Műegyetemen zajlik október 22-én és 23-án.
                </p>
                <p>
                    A Gloria Victis történelmi vetélkedőre 2024. március 15-től lehet
                    jelentkezni ezen az oldalon, ahol minden további részletek is
                    elérhető.
                </p>
                <p>
                    A jelentkezést követően elérhetővé válik a vetélkedő első fordulójának
                    feladatsora, amelynek beküldési határideje
                    <b>2024. június 9., vasárnap éjfél</b>
                </p>
                <p>
                    <b>A csapat adatai és a feladatlap a beküldési határidőig online szerkeszthető. A szakmai zsűri
                        kizárólag azon csapatok feladatlapját bírálja el, ahol a csapatra és csapattagokra vonatkozó
                        adatok kitöltése hiánytalanul megtörtént! A zsűri a rendszerbe beérkezett feladatlapokat a 2024.
                        június 9. éjféli állapotuk szerint pontozza!</b>
                </p>
                <p>
                    Az első forduló eredményéről
                    <b>2024. szeptember 9.</b> értesítjük a csapatokat, illetve az
                    eredmények megtekinthetővé válnak a Rákóczi Szövetség honlapján is.
                </p>
                <p>
                    A második fordulóba jutott 60 csapat október 22-én írásbeli fordulón
                    méri össze tudását, és ennek eredménye alapján a legeredményesebb tíz
                    csapat kerül be az október 23-i szóbeli döntőbe. A döntőben neves
                    szakemberekből álló zsűri előtt, számos igényes feladat révén dől el a
                    vetélkedő végső sorrendje. Valamennyi döntőbe és középdöntőbe jutott
                    csapat értékes díjakban, a legjobb három csapat tagjai felkészítő
                    tanáraikkal együtt egy külföldi tanulmányi kirándulásban részesülnek
                </p>
                <v-divider class="my-4"></v-divider>
                <p style="font-size: 1.1rem; font-weight: bold">
                    A jelentkezés menete:
                </p>
                <v-timeline side="end" class="my-2 w-100">
                    <v-timeline-item :dot-color="`#cc333b`" size="small" density="compact">
                        <template #icon>
                            <span class="text-white">1.</span>
                        </template>
                        <v-alert height="200">
                            <v-row>
                                <v-col cols="12">
                                    <div>
                                        Regisztráljanak egyetlen felhasználói fiókot csapatuk
                                        részére (személyenként nem szükséges külön regisztrálni).
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn @click="$router.push({ name: `home` })">REGISZTRÁLOK</v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-timeline-item>
                    <v-timeline-item :dot-color="`#cc333b`" size="small" density="compact">
                        <template #icon>
                            <span class="text-white">2.</span>
                        </template>
                        <v-alert height="200">
                            <v-row>
                                <v-col cols="12">
                                    <div>
                                        Miután a regisztrációt elvégezte,
                                        jelentkezzen be és töltse ki a csapat adatait - ez
                                        feltétlenül szükséges az ajánlott irodalom megismeréséhez és
                                        a feladatok eléréséhez.
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn @click="$router.push({ name: `home` })">BELÉPEK</v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-timeline-item>
                </v-timeline>
            </section>
            <section>
                <h5 class="text-h5 mb-1 mt-6">A feladatok kitöltése</h5>
                <p>
                    <strong>Kérjük, gyakran mentse el munkáját az egyes feladatok végén
                        található "Mentés" gombbal!</strong>
                    Ha egy feladatlapot elhagy, mindig kattintson a MENTÉS gombra!
                </p>
                <p>
                    Az elmentett feladatlapok a következő megnyitáskor a legutolsó mentés
                    állapotában töltődnek be.
                </p>
                <p>
                    A 2024. június 9. (vasárnap) éjféli határidő lejártát követően a
                    feladatlapok már nem lesznek elérhetők, a pontozáshoz az utolsó mentés
                    szerint beküldött válaszokat vesszük alapul.
                </p>
                <p>
                    <strong>A határidőt követően csak azon felhasználók feladatlapja kerül
                        értékelésre, akik a csapat adatait hiánytalanul kitöltötték!</strong>
                </p>
                <p>
                    Ha úgy érzni, minden feladattal végzett és azokat elmentette,
                    leellenőrizte, nincs további teendője.
                </p>
            </section>
            <section>
                <h5 class="text-h5 mb-1 mt-6">Kérdés, észrevétel</h5>
                <p>
                    A vetélkedővel kapcsolatos további információ az <b>info@rakocziszovetseg.hu</b> e-mail címen
                    kérhető.
                </p>
            </section>
        </v-card-text>
    </v-card>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: `InfoPage`,
    data() {
        return {
            phone: `+36 1 201 3067`,
            email: `gloriavictis@rakocziszovetseg.hu`,
            termsOfUse: ``,
            dataManagement: ``,
        };
    },
});
</script>
