<template>
  <v-row class="justify-center py-12">
    <v-card :width="$vuetify.display.mobile ? `100%` : `50%`" :max-width="$vuetify.display.mobile ? `100%` : `50%`"
      :loading="loading" :disabled="loading">
      <v-window v-model="tab">
        <v-window-item value="one">
          <v-card-title class="mt-4">BELÉPÉS</v-card-title>
          <v-card-actions style="padding: 1rem">
            <v-row class="justify-space-between align-center">
              <v-col cols="12">
                <div class="font-italic font-weight-medium text-center"
                  :class="$vuetify.display.mobile ? `text-center` : `text-left`">
                  Lépj be Google fiókoddal! Kattints a gombra!
                </div>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="consent" validate-on="input">
                  <template #label>
                    <label>
                      A mező bejelölésével Ön nyilatkozik, hogy adatai kezelését
                      illetően a Rákóczi Szövetség
                      <a :href="tou" target="_blank" style="color: black; text-decoration: underline">Adatkezelési
                        Tájékoztatójában</a>
                      és
                      <a :href="dmr" target="_blank" style="color: black; text-decoration: underline">Adatvédelmi
                        Szabályzatában</a>
                      foglaltak szerint keletkező jogait és kötelezettségeit
                      megismerte és elfogadja.
                    </label>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-alert density="compact" icon="mdi-information" title="FIGYELEM!"
                  text="A csapat minden tagjának eléréssel kell rendelkeznie ehhez a fiókhoz a közös munka érdekében!"
                  color="rsz50" style="text-align: start"></v-alert>
              </v-col>
              <v-col cols="12">
                <v-btn size="large" class="w-100" variant="outlined" @click="googleAuth" :disabled="!consent">
                  <SvgComponent :size="24" name="google"></SvgComponent>
                  <span class="ml-4">GOOGLE BELÉPÉS</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-window-item>
      </v-window>
    </v-card>
  </v-row>
</template>

<script>
import { defineComponent } from 'vue'
import SvgComponent from './SvgComponent.vue'
import tou from '../assets/RakocziSzovetseg_AdatkezelesiTajekoztato.pdf'
import { googleTokenLogin } from "vue3-google-login"
import dmr from '../assets/RakocziSzovetseg_Adatvedelm_es_Kozerdeku_Adatok_Megismeresenek_Rendejerol_sz_Szabalyzat20211221.pdf'
import { login } from '../services/userServices'

export default defineComponent({
  name: `AuthComponent`,
  components: { SvgComponent },
  data() {
    return {
      loading: null,
      tab: null,
      consent: null,
      formComplete: false,
      verifyMsg: false,
      tou: tou,
      dmr: dmr,
    }
  },
  methods: {
    async googleAuth() {
        if(process.env.VUE_APP_ENV == 'dev'){
        if((await login('access_token')).data){
          await this.$router.push('team')
        }else{
          console.log('Sikertelen bejelentkezés')
        }
        window.location.reload()
        return
      }

      googleTokenLogin().then(async (response) => {
        if((await login(response.access_token)).data){
          await this.$router.push('team')
          window.location.reload()
        }else{
          console.log('Sikertelen bejelentkezés')
        }
      })
    },
  },
})
</script>

<style></style>
