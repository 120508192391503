<template>
  <v-toolbar :class="$vuetify.display.mobile ? `px-2` : `px-8`" height="80" color="rsz500">
    <v-toolbar-title class="d-flex justify-start align-center pa-0 ma-0" @click="navigateHome">
      <v-btn v-if="$vuetify.display.mobile" height="60">
        <LogoComponent :size="38" :color="`white`"></LogoComponent>
      </v-btn>
      <v-btn v-else>
        <LogoComponent :size="38" :color="`white`"></LogoComponent>
        <span class="text-h6 mx-8">Gloria Victis 2024 </span>
      </v-btn>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <nav v-if="$vuetify.display.mobile">
      <nav>
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-navigation-drawer v-model="drawer" height="100vh" width="100vw" color="rsz500" location="bottom" temporary>
          <v-list>
            <v-list-item @click="drawer = !drawer">
              <v-list-item-title class="text-rsz100 font-weight-bold text-end">
                <v-icon icon="mdi-close"></v-icon>
              </v-list-item-title>
            </v-list-item>
            <span class="text-h6 mx-8" color="">Gloria Victis 2024 </span>
            <v-list-item v-if="isLoggedIn" dense router to="/team" @click="drawer = !drawer">
              <v-list-item-title class="text-rsz100 font-weight-bold pa-3 d-flex">Csapat adatai</v-list-item-title>
            </v-list-item>
            <v-divider class="border-opacity-25 mx-6"></v-divider>
            <v-list-item v-if="isLoggedIn" dense router to="/tasks" @click="drawer = !drawer">
              <v-list-item-title class="text-rsz100 font-weight-bold pa-3 d-flex">Feladatok</v-list-item-title>
            </v-list-item>
            <v-divider class="border-opacity-25 mx-6"></v-divider>
            <v-list-item v-if="isLoggedIn" dense router to="/literature" @click="drawer = !drawer">
              <v-list-item-title class="text-rsz100 font-weight-bold pa-3 d-flex">Ajánlott irodalom</v-list-item-title>
            </v-list-item>
            <v-divider class="border-opacity-25 mx-6"></v-divider>
            <v-list-item dense @click="openDialog">
              <v-list-item-title class="text-rsz100 font-weight-bold pa-3 d-flex">Tudnivalók</v-list-item-title>
            </v-list-item>
            <v-divider class="border-opacity-25 mx-6"></v-divider>
            <v-list-item dense router to="/info" @click="drawer = !drawer">
              <v-list-item-title class="text-rsz100 font-weight-bold pa-3 d-flex">Felhívás</v-list-item-title>
            </v-list-item>
            <v-divider class="border-opacity-25 mx-6"></v-divider>
            <v-list-item v-if="isLoggedIn" dense router to="" @click="handleLogout">
              <v-list-item-title class="text-rsz100 font-weight-bold pa-3 d-flex">Kilépés</v-list-item-title>
            </v-list-item>
            <v-divider class="border-opacity-25 mx-6"></v-divider>
            <v-list-item v-if="!isLoggedIn" dense router to="/" @click="drawer = !drawer">
              <v-list-item-title class="text-rsz100 font-weight-bold pa-3 d-flex">Belépés</v-list-item-title>
            </v-list-item>
            <v-divider class="border-opacity-25 mx-6"></v-divider>
          </v-list>
        </v-navigation-drawer>
      </nav>
    </nav>
    <nav v-else>
      <nav>
        <v-btn v-if="isLoggedIn"  dense router to="/team">
          <div to="/team" class="text-rsz100 font-weight-bold">Csapat Adatai</div>
        </v-btn>
        <v-btn v-if="isLoggedIn"   dense router to="/tasks">
          <div to="/tasks" class="text-rsz100 font-weight-bold">Feladatok</div>
        </v-btn>
        <v-btn v-if="isLoggedIn"   dense router to="/literature">
          <div to="/literature" class="text-rsz100 font-weight-bold">Ajánlott Irodalom</div>
        </v-btn>
        <v-btn class="text-rsz100 font-weight-bold" @click="openDialog">
          Tudnivalók
        </v-btn>
        <v-btn  dense router to="/info">
          <div to="/info" class="text-rsz100 font-weight-bold">Felhívás</div>
        </v-btn>
        <v-btn v-if="isLoggedIn" class="text-rsz100 font-weight-bold" @click="handleLogout">
          Kilépés
        </v-btn>
        <v-btn v-if="!isLoggedIn"  dense router to="/">
          <div to="/" class="text-rsz100 font-weight-bold">Belépés</div>
        </v-btn>
      </nav>
    </nav>
  </v-toolbar>
</template>

<script>
import { defineComponent } from 'vue'
import LogoComponent from './LogoComponent.vue'
import { logout } from '@/services/userServices'
import { checkLogin } from '../services/userServices'

export default defineComponent({
  name: 'NavMenu',
  components: { LogoComponent },
  emits: ['openIntroDialog'],
  data() {
    return {
      drawer: false,
      group: null,
      isLoggedIn: false,
    }
  },
  created() {
    this.loggedIn()
  },
  watch: {
    drawer(newValue) {
      if (newValue) {
        document.body.classList.add('nocroll')
      } else {
        document.body.classList.remove('nocroll')
      }
    },
    group() {
      this.drawer = false
    },
  },
  methods: {
    navigate(route){
      this.$router.push(route)
    },
    navigateHome() {
      this.navigate('/')
    },
    openDialog() {
      if (this.drawer) {
        this.drawer = !this.drawer
      }
      this.$emit(`openIntroDialog`, true)
    },
    async handleLogout() {
      await logout()
      window.location.reload()
      this.navigateHome()
    },
    async loggedIn() {
      this.isLoggedIn = await checkLogin()
    },
  },
})
</script>
