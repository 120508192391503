import { apiClient } from './axios'

//csapat adatok beolvasása
/**
 *
 * @returns {
 *  name: string,
 *  created_by_user_id: uuid,
 *  created_at: date,
 *  updated_at: date,
 * }
 */
export const getTeam = async () => {
  return await apiClient.get('api/teams')
}

export const checkTeamName = async (name) => {
  try {
    return (await apiClient.get(`api/check-team/${name}`)).data
  }catch (err){
    console.error('Hiba történt, kérem próbálkozzon újra')
    return false
  }
}

/**
 *
 * @param {string} name
 * @returns bool
 */
export const postTeam = async (teamData) => {
  try {
    return await apiClient.post('api/teams', teamData)
  }catch (err){
    console.error('Hiba történt, kérem próbálkozzon újra')
    return false
  }
}

/**
 *
 * @param {string} name
 * @returns bool
 */
export const putTeam = async (teamData) => {
  try {
    return await apiClient.put('api/teams', teamData)
  }catch (err){
    console.error('Hiba történt, kérem próbálkozzon újra')
    return false
  }
}
