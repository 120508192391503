import { apiClient } from './axios'

/**
 * a felvett csapattagokat adja vissza
 * @returns [{
 *    name: strig,
 *    email: string,
 *    telephone: string,
 *    team_id: uuid,
 * }]
 */
export const getTeamMembers = async () => {
  try {
    return await apiClient.get(`api/team-members`)
  }catch (err){
    console.error('Hiba történt, kérem próbálkozzon újra')
    return false
  }
}

/**
 *
 * @param {
 *   name: strig,
 *   email: string,
 *   telephone: string,
 *   team_id: uuid,
 * } member1
 * @param {*} member2
 * @param {*} member3
 * @returns bool
 */
export const postTeamMembers = async (dataForm) => {
  try {
    return await apiClient.post('api/team-members', {
      teacherData: dataForm.teacherData,
      member1: dataForm.member1,
      member2: dataForm.member2,
      member3: dataForm.member3,
    })
  }catch (err){
    console.error('Hiba történt, kérem próbálkozzon újra')
    return false
  }
}

/**
 *
 * @param {
 *   name: strig,
 *   email: string,
 *   telephone: string,
 *   team_id: uuid,
 * } member1
 * @param {*} member2
 * @param {*} member3
 * @returns bool
 */
export const putTeamMembers = async (dataForm) => {
  try {
    return await apiClient.put('api/team-members', {
      teacherData: dataForm.teacherData,
      member1: dataForm.member1,
      member2: dataForm.member2,
      member3: dataForm.member3,
    })
  } catch (err) {
    console.error('Hiba történt, kérem próbálkozzon újra')
    return false
  }
}
