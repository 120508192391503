<template>
  <svg
    v-if="name === `google`"
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
  >
    <path
      fill="#4285F4"
      d="m22.6 12.3-.2-2.3H12v4.3h6a5 5 0 0 1-2.3 3.3v2.7h3.6c2-1.9 3.3-4.7 3.3-8z"
    />
    <path
      fill="#34A853"
      d="M12 23c3 0 5.5-1 7.3-2.7l-3.6-2.7a7 7 0 0 1-3.7 1 6.6 6.6 0 0 1-6.2-4.5H2.2v2.8A11 11 0 0 0 12 23z"
    />
    <path
      fill="#FBBC05"
      d="M5.8 14a6.6 6.6 0 0 1 0-4V7H2.2a10.9 10.9 0 0 0 0 10L5 14.6l.8-.6z"
    />
    <path
      fill="#EA4335"
      d="M12 5.4A6 6 0 0 1 16.2 7l3.2-3A11 11 0 0 0 2.2 7l3.6 2.8A6.6 6.6 0 0 1 12 5.4z"
    />
    <path fill="none" d="M1 1h22v22H1z" />
  </svg>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: `SvgComponent`,
  props: {
    name: {
      type: String,
      defailt: `rsz`,
      required: true,
    },
    size: {
      type: Number,
      default: 48,
      required: true,
    },
  },
});
</script>
