import { createApp, watch } from 'vue'
import App from './App.vue'
import "./style.css";

// Store
import { createPinia } from "pinia";

import { router } from "./router/index";
import { createVuetify } from "vuetify/lib/framework.mjs";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import vue3GoogleLogin from 'vue3-google-login'


const pinia = createPinia();

export const SESSION_KEY = "rszgv-pinia";

console.log(`
@@@@@@@@@@@#((((((((((((((@@@@@@@@@@@@@@
@@@@@@@@@((((((((((@@%((((@@@@@@@@@@@@@@
@@@@@@@((((((((((@@@@@@@((@@@@@@@@@@@@@@
@@@@#(((&@@@@@@(((@@@@@(((@@@@@@%(((&@@@
@@@((((((((@@@@(((((((((((@@@@((((((((@@
@@(((((((((((@@(((((((((((@%(((((((((((@
@((((((((((((((@@@@@@@@@@@((((((((((((((
(((((((((((((((@@@@@@@@@@@((((((((((((((
(((((((((((((((@@@@@@@@@@@((((((((((((((
@@@@@@@@(((((((((@@@@@@@(((((((((@@@@@@@
@(((((((((((((((((((@(((((((((((((((((((
@@((((((((((((((@(((((((@((((((((((((((@
@@@(((((((((((%@(((((((((@((((((((((((@@
@@@@@((((((((@@(((((((((((@@((((((((@@@@
@@@@@@@((((@@@(((((((((((((@@@(((#@@@@@@
@@@@@@@@@@@@@(((((((((((((((@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@#(((@@@@(((((((((((@@@((((((@@@@@
@@@@@((((((((&@(((((((((((@@@((((((@@@@@
@@@@@@@((((@@@@(((((((((((@@@((((((@@@@@
@@@@@@@@@@@@@@@@%(((((((@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@(((@@@@@@@@@@@@@@@@@@
`);
console.log(`Welcome to RSZ Gloria Victis 2024 App`);

/**
 * Keeping an eye on any store state changes.
 * If state changes, we will update the sessionStorage.
 * NOTE: sessionStorage is the proper one to hold User
 */
watch(
  pinia.state,
  (state) => {
    const rszgv24_pinia = {};
    if (state.auth) {
      rszgv24_pinia.auth = {
        user: state.auth.user,
      };
    }
    if (state.consent) {
      rszgv24_pinia.consent = state.consent;
    }
    if (state.submit) {
      rszgv24_pinia.submit = state.submit;
    }
    if (state.backend) {
      rszgv24_pinia.backend = state.backend;
    }
    if (state.notification) {
      rszgv24_pinia.notification = state.notification;
    }
    window.sessionStorage.setItem(SESSION_KEY, JSON.stringify(rszgv24_pinia));
    if (state.consent) {
      window.localStorage.setItem(
        SESSION_KEY,
        JSON.stringify({ consents: state.consent })
      );
    }
  },
  { deep: true }
);

const rszLightTheme = {
    dark: false,
    colors: {
      background: "#FFFFFF",
      surface: "#FFFFFF",
      primary: "#6200EE",
      "primary-darken-1": "#3700B3",
      secondary: "#03DAC6",
      "secondary-darken-1": "#018786",
      error: "#B00020",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FB8C00",
      rsz50: "#f9e7e7",
      rsz100: "#f0c2c4",
      rsz200: "#e6999d",
      rsz300: "#db7076",
      rsz400: "#d45258",
      rsz500: "#cc333b",
      rsz600: "#c72e35",
      rsz700: "#c0272d",
      rsz800: "#b92026",
      rsz900: "#ad1419",
      rszA100: "#ffe0e0",
      rszA200: "#ffadae",
      rszA400: "#ff7a7c",
      rszA700: "#ff6063",
    },
  };
  
  const vuetify = createVuetify({
    components,
    directives,
    theme: {
      defaultTheme: "rszLightTheme",
      themes: {
        rszLightTheme,
      },
      icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
          mdi,
        },
      },
    },
  });

const app = createApp(App);
app.use(vuetify);
app.use(router);
app.use(pinia);


app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
})


app.mount("#app");

app.config.globalProperties.$isMobile = vuetify.display.mobile._object.mobile;