<template>
    <v-row justify="center">
        <v-dialog v-model="show" persistent :max-width="$vuetify.display.mobile ? `90vw` : `60vw`">
            <v-card>
                <div class="w-100 d-flex justify-end align-center">
                    <v-btn icon="mdi-close" color="white" elevation="0" @click="closeDialog"></v-btn>
                </div>
                <v-card-title class="text-h6 text-center">
                    Fontos tudnivalók
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <div class="text-h6">REGISZTRÁCIÓ, BELÉPÉS</div>
                            </v-col>
                            <v-col cols="12">
                                <div>
                                    Az oldal, a feladatlapok és a szakirodalom eléréséhez
                                    csapatonként <b>1 felhasználói regisztrációra</b> van szükség,
                                    ezt néhány kattintással megtehetik. A regisztrációt és a
                                    csapat adatainak megadását követően már elérhetik a
                                    feladatlapokat és az ajánlott irodalomjegyzéket.
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <v-alert density="compact" icon="mdi-information" title="FIGYELEM!"
                                    text="Amennyiben meglévő Google fiókkal regisztrálnak, a csapat minden tagjának eléréssel kell rendelkeznie ehhez a fiókhoz a közös munka érdekében!"
                                    color="rsz50" style="text-align: start"></v-alert>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="text-h6">KITÖLTÉS</div>
                            </v-col>
                            <v-col cols="12">
                                <div>
                                    A feladatlapok kitöltésére a megadott határidőig van
                                    lehetőség. Minden feladatlap elmenthető - így legközelebb
                                    onnan folytathatják, ahol abbahagyták.<br /><b>FONTOS: A Gloria Victis webalkalmazás
                                        optimális működése
                                        érdekében kérjük, asztali számítógépet használjon, a
                                        javasolt böngésző: Google Chrome legfrissebb verziója. (Amennyiben a böngésző bal felső sarkában
                                        Frissítés/Update feliratú gombot lát, kérjük frissítse
                                        böngészőjét!)</b>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="text-h6">VÉGSŐ MENTÉS</div>
                            </v-col>
                            <v-col cols="12">
                                <div>
                                    Amennyiben úgy érzik, hogy a kitöltéssel elkészültek, kérjük,
                                    mentsék a feladatlap(oka)t, az oldalt frissítve
                                    ellenőrizhetik, hogy valóban a kívánt állapotban történt-e meg
                                    a mentés. Ha mindent rendben találnak, nincs további
                                    teendőjük, a határidő lejártakor rögzített állapot szerint fog
                                    elkészülni az értékelés.
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="text-h6">TOVÁBBI INFORMÁCIÓ</div>
                            </v-col>
                            <v-col cols="12">
                                <div>
                                    Ezt a felugró ablakot a későbbiekben bármikor megnyithatják a
                                    menüben a <b>Tudnivalók</b> lehetőséget választva. Amennyiben
                                    további információra van szüksége, a menüben válassza az
                                    <b>Információ</b> lehetőséget.
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-row style="width: 100%">
                        <v-col cols="12" class="d-flex justify-center align-center">
                            <v-btn color="rsz500" size="x-large" variant="text" @click="closeDialog">
                                RENDBEN, KEZDHETJÜK
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <span ref="dialogend"></span>
                <v-row class="scroll-row" style="background-color: white">
                    <v-col cols="12" class="d-flex justify-center align-center">
                        <v-btn icon="mdi-chevron-down" color="rsz500" @click="scrollDown"></v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: `IntroDialog`,
    props: {
        showdialog: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["dialogChange"],
    data() {
        return {
            show: this.showdialog,
        };
    },
    watch: {
        showdialog(newValue) {
            this.show = newValue;
        },
    },
    methods: {
        closeDialog() {
            this.show = false;
            this.$emit(`dialogChange`, this.show);
        },
        scrollDown() {
            this.$refs.dialogend.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
            this.$refs.dialogend.sc;
        },
    },
});
</script>
<style>
.scroll-row {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
}
</style>
