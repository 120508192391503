<template>
  <v-row class="justify-center align-center h-100 w-100 ma-0" v-if="!loading">
    <AuthComponent v-if="!isLoggedIn"></AuthComponent>
  </v-row>
  <v-card class="py-4 w-100" :class="$vuetify.display.mobile ? `px-0` : `px6`" v-if="isLoggedIn">
    <v-card-title class="text-h4 text-end mb-10">KEZDŐLAP</v-card-title>
    <v-container class="text-start" :class="$vuetify.display.mobile ? `pa-0` : ``">
      <v-row v-if="!isValidteam">
        <v-col>
          <v-alert
            title="FIGYELEM! A csapat adatai nincsenek megfelelően kitöltve! Kérjük adja meg a csapattagok és a felkészítő tanár adatait!"
            type="warning"></v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h5 class="text-h5 text-center font-weight-bold">
            Üdvözölünk a Gloria Victis 2024 vetélkedőn{{ data.teamName ? `, ${data.teamName} csapat!` : '!' }}
          </h5>
          <h2>
            Kedves Versenyzők!
Ezúton értesítünk benneteket, hogy a 2024-es Gloria Victis vetélkedő határideje lezárult. Köszönjük mindenkinek a részvételt és a lelkesedést! Az eredmények kihirdetésére hamarosan sor kerül.
          </h2>
        </v-col>
      </v-row>
      <v-divider class="mt-4 mb-12"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-list>
            <v-list-item v-if="data.school">Iskola: {{ data.school }}</v-list-item>
            <v-list-item v-if="data.teamName">Csapatnév: {{ data.teamName }}</v-list-item>
            <v-list-item v-if="data.teacher">Felkészítő tanár: {{ data.teacher }}</v-list-item>
            <v-list-item v-for="(member, idx) in data.members" :key="idx">{{ idx + 1 }}. csapattag: {{ member
              }}</v-list-item>
          </v-list>
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12">
          <v-row class="justify-space-around">
            <v-col cols="12" md="4" class="d-flex justify-center pa-6">
              <v-btn color="rsz500" class="w-100" variant="outlined"
                @click="$router.push(`/literature`)">IRODALOM</v-btn>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-center pa-6">
              <v-btn color="rsz500" class="w-100" @click="$router.push(`/tasks`)" disabled="true">FELADATOK</v-btn>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-center pa-6">
              <v-btn color="rsz500" class="w-100" variant="outlined" @click="$router.push(`/team`)">CSAPAT
                ADATAI</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="mt-4 mb-12"></v-divider>
      <v-row>
        <v-col cols="12" class="pa-6">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title>FELHÍVÁS</v-expansion-panel-title>
              <v-expansion-panel-text :class="$vuetify.display.mobile ? `pa-2` : `pa-10`">
                <v-row>
                  <v-col cols="12">
                    <h6 class="text-h6 text-center">
                      GLORIA VICTIS – 2024<br />Kárpát-medencei középiskolai
                      történelmi vetélkedő
                    </h6>
                  </v-col>
                  <v-col cols="12">
                    <p>
                      A Rákóczi Szövetség 2024-ben 29. alkalommal hirdeti meg Kárpát-medencei középiskolások számára
                      a Gloria Victis történelmi vetélkedőt, amelynek idei témája:
                    </p>
                    <p class="text-center">
                      <b>Forradalom és szabadságharc (1848-1849)</b>
                    </p>
                    <p>
                      A három fordulóból álló vetélkedő meghirdetése minden évben tavasszal történik. A háromfős
                      csapatok egy tesztsor kitöltésével nevezhetnek. A beküldött dolgozatok alapján a legjobb 60 csapat
                      meghívást kap a második fordulóra, amely részét képezi a Gloria Victis 1956-os Ifjúsági
                      Találkozónak Budapesten október 21. és 23. között.
                    </p>
                    <p>
                      A vetélkedő középdöntője és döntője a Műegyetemen zajlik október 22-én és 23-án.
                    </p>
                    <p>
                      A Gloria Victis történelmi vetélkedőre 2024. március
                      15-től lehet jelentkezni ezen az oldalon, ahol minden
                      további részletek is elérhető.
                    </p>
                    <p>
                      A jelentkezést követően elérhetővé válik a vetélkedő
                      első fordulójának feladatsora, amelynek beküldési
                      határideje <b>2024. június 9., vasárnap éjfél.</b>
                    </p>
                    <p>
                      <b>A csapat adatai és a feladatlap a beküldési határidőig online szerkeszthető. A szakmai zsűri
                        kizárólag azon csapatok feladatlapját bírálja el, ahol a csapatra és csapattagokra vonatkozó
                        adatok kitöltése hiánytalanul megtörtént! A zsűri a rendszerbe beérkezett feladatlapokat a 2024.
                        június 9. éjféli állapotuk szerint pontozza!</b>
                    </p>
                    <p>
                      Az első forduló eredményéről
                      <b>2024. szeptember 9. után</b> értesítjük a
                      csapatokat, illetve az eredmények megtekinthetővé válnak
                      a Rákóczi Szövetség honlapján is.
                    </p>
                    <p>
                      A második fordulóba jutott 60 csapat október 22-én
                      írásbeli fordulón méri össze tudását, és ennek eredménye
                      alapján a legeredményesebb tíz csapat kerül be az
                      október 23-i szóbeli döntőbe. A döntőben neves
                      szakemberekből álló zsűri előtt, számos igényes feladat
                      révén dől el a vetélkedő végső sorrendje. Valamennyi
                      döntőbe és középdöntőbe jutott csapat értékes díjakban,
                      a legjobb három csapat tagjai felkészítő tanáraikkal
                      együtt egy külföldi tanulmányi kirándulásban részesülnek
                    </p>
                    <p>
                      A vetélkedővel kapcsolatos további információ az
                      <a
                        href="mailto:info@rakocziszovetseg.hu?subject=K%C3%A9rd%C3%A9s%2C%20%C3%A9szrev%C3%A9tel%20-%20Gloria%20Victis">info@rakocziszovetseg.hu</a>
                      e-mail címen kérhető.
                    </p>
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import AuthComponent from "../components/AuthComponent.vue";
import { checkLogin, checkTeam } from "@/services/userServices";
import { getTeam } from "@/services/teamServices";
import { getTeamMembers } from "@/services/teamMemberServices";

export default defineComponent({
  name: 'HomePage',
  components: {
    AuthComponent
  },
  data() {
    return {
      loading: true,
      isLoggedIn: false,
      isValidteam: true,
      data: {
        school: null,
        teamName: null,
        teacher: null,
        members: [],
      }
    }
  },
  created() {
    this.getTeamData()
  },
  methods: {
    async getTeamData() {
      this.isLoggedIn = await checkLogin()

      this.isValidteam = await checkTeam()
      this.loading = false
      if (!this.isLoggedIn) return

      try {
        const responseTeam = (await getTeam()).data
        const { member1, member2, member3, teacherData } = (await getTeamMembers()).data

        this.data.school = responseTeam.value
        this.data.teamName = responseTeam.name
        this.data.teacher = `${teacherData.lastName} ${teacherData.firstName}`

        this.data.members = [member1, member2, member3].reduce((acc, curr) => {
          if (!curr.firstName || !curr.lastName) return acc
          return [
            ...acc,
            `${curr.firstName} ${curr.lastName}`
          ]
        }, [])
      } catch (err) {
        this.isValidteam = false
      }
    },
  },
})
</script>

<style scoped>
p {
  margin-top: 1rem;
  text-align: justify;
}
</style>
