import { apiClient } from './axios'

export const login = async (token) => {
  try {
    return await apiClient.post(`api/login`, { token })
  }catch (err){
    console.error('Hiba történt, kérem próbálkozzon újra')
    return false
  }
}

export const logout = async () => {
  try {
    return await apiClient.get('api/logout')
  }catch (err){
    console.error('Hiba történt, kérem próbálkozzon újra')
    return false
  }
}

export const checkLogin = async () => {
  try {
    return (await apiClient.get('api/check-login')).data
  }catch (err){
    console.error('Hiba történt, kérem próbálkozzon újra')
    return false
  }
}

export const checkTeam = async () => {
  try {
    return (await apiClient.get('api/is-valid-team')).data
  }catch (err){
    console.error('Hiba történt, kérem próbálkozzon újra')
    return false
  }
}
