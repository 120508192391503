import { defineStore } from "pinia";
import { useConsentStore } from "./ConsentStore";

const prevUser = JSON.parse(window.sessionStorage.getItem("rszgv-pinia"));

export const useAuthStore = defineStore("auth", {
  state: () => {
    const user =
      prevUser && prevUser.auth && prevUser.auth.user
        ? prevUser.auth.user
        : null;
    const consentStore = useConsentStore();
    return {
      user: user,
      consentStore: consentStore,
    };
  },
  getters: {
    hasConsentGranted: (state) => {
      return state.user ? state.consentStore.isDataManagementConsent : true;
    },
  },
    actions: {
        grantConsent(value) {
            this.consentStore.setDataManagementConsent(value);
            const userToSet = {
                ...this.auth.currentUser,
                user_displayName: this.auth.currentUser.displayName,
                user_consent: value,
            };
            this.backendStore.setUser(userToSet);
            this.backendStore.sendUserRsz();
            this.backendStore.sendFirestore().catch((e) => console.error("ERROR", e));
        },
        resetStore() {
            const prevState = JSON.parse(
                window.sessionStorage.getItem("rszgv-pinia")
            );
            if (prevState && prevState.backend) {
                const newState = { ...prevState, backend: null };
                window.sessionStorage.setItem("rszgv-pinia", JSON.stringify(newState));
            }
            this.user = null;
            this.registration = null;
        },   
    },
});
