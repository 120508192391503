<template>
    <v-card class="py-4 w-100" :class="$vuetify.display.mobile ? `px-0` : `px6`">
        <v-card-title class="text-h4 text-end mb-4">AJÁNLOTT IRODALOM</v-card-title>
        <v-card-text>
            <v-container class="text-start" :class="$vuetify.display.mobile ? `pa-0` : ``">
                <v-row>
                    <v-col cols="12">
                        <h6 class="text-h6">
                            Alább találják a felkészükéshez
                            <u>ajánlott</u> irodalomjegyzéket. A legtöbb forrás <b>kattintásra</b>
                            nyitható meg. Eredményes felkészülést és munkát kívánunk!
                        </h6>
                    </v-col>
                </v-row>
                <v-divider class="mt-4 mb-12"></v-divider>
                <v-row>
                    <v-col cols="12">
                        <v-icon icon="mdi-information"></v-icon>
                        <a class="ma-2"
                            href="https://mandadb.hu/dokumentum/651675/A_negyvennyolcas_forradalom_krdsei.pdf">
                            A negyvennyolcas forradalom kérdései in: Értekezések a történeti tudományok köréből.
                            Akadémiai
                            kiadó, Bp. 1976.
                        </a>
                        <v-divider class="ma-2"></v-divider>

                        <a class="ma-2" variant="tonal">
                            Az 1848 – 49-es forradalom és szabadságharc. Szerk.: Hermann Róbert, Osiris, Bp. 2022.
                        </a>
                        <v-divider class="ma-2"></v-divider>
                        <v-icon icon="mdi-information"></v-icon>
                        <a class="ma-2" href="https://epa.oszk.hu/00000/00016/00036/980621.htm">
                            Bona Gábor: A szabadságharc honvédsége. in: Új Forrás folyóirat, 1998. 6. sz.
                        </a>
                        <v-divider class="ma-2"></v-divider>
                        <v-icon icon="mdi-information"></v-icon>
                        <a class="ma-2" href="https://mandadb.hu/tetel/182474/A_tizenkilencedik_szazad_tortenete">
                            Csorba László: A tizenkilencedik század története. Pannonica kiadó, Budapest, 2000.
                        </a>
                        <v-divider class="ma-2"></v-divider>
                        <a class="ma-2" variant="tonal">
                            Gergely András: Magyarország története a 19. században. Osiris, Budapest, 2003.
                        </a>
                        <v-divider class="ma-2"></v-divider>
                        <v-icon icon="mdi-information"></v-icon>
                        <a class="ma-2" href="https://mek.oszk.hu/04700/04739/html/">
                            Görgei Artúr: Életem és működésem Magyarországon 1848 – 49-ben. Neumann Kht., Budapest,
                            2004.
                        </a>
                        <v-divider class="ma-2"></v-divider>
                        <v-icon icon="mdi-information"></v-icon>
                        <a class="ma-2"
                            href="https://epa.oszk.hu/03100/03122/00018/pdf/EPA03122_rubicon_2017_10_024-033.pdf">
                            Hermann Róbert: 1848: Forradalom és / vagy szabadságharc? in: Rubicon, 2017. októberi szám
                            (28. évf. 10. sz.)
                        </a>
                        <v-divider class="ma-2"></v-divider>
                        <v-icon icon="mdi-information"></v-icon>
                        <a class="ma-2" href="https://rubicon.hu/cikkek/1848-1849-legendai">
                            Hermann Róbert: 1848 legendái.
                        </a>
                        <v-divider class="ma-2"></v-divider>
                        <v-icon icon="mdi-information"></v-icon>
                        <a class="ma-2" href="https://rubicon.hu/cikkek/1848-49">
                            Hermann Róbert: 1848-49: Magyarország az európai politika erőterében.
                        </a>
                        <v-divider class="ma-2"></v-divider>
                        <v-icon icon="mdi-information"></v-icon>
                        <a class="ma-2" href="http://www.okm.gov.hu/letolt/kozokt/libri/konyv_v.pdf">
                            Hermann Róbert: Az 1848 – 49-es forradalom és szabadságharc vértanúi – október 6. OM, 2000.
                        </a>
                        <v-divider class="ma-2"></v-divider>
                        <a class="ma-2" variant="tonal">
                            Hermann Róbert: Forradalom és szabadságharc 1848 – 1849 in: Magyarország története. Kossuth
                            kiadó, Budapest, 2009.
                        </a>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
    name: `LiteraturePage`,
    data() {
        return {
        };
    },
    methods: {
        redirectToPDF(link) {
            window.open(link, '_blank');
        }
    }
});
</script>
<style scoped>
.cat-header {
    font-size: 1.15rem;
    text-transform: uppercase;
    font-weight: 500;
    border-bottom: 2px solid darkgray;
}

.literature-link {
    color: rgb(75, 75, 75);
}

.literature-link::after {
    content: "(Kattintson a megnyitáshoz)";
    padding-left: 1rem;
}
</style>