<template>
    <v-dialog v-model="show" persistent class="h-100 pa-0 ma-0" height="auto"
        :max-width="$vuetify.display.mobile ? `90vw` : `35vw`">
        <v-card>
            <v-card-text>
                Az oldal működéséhez kizárólag szükséges sütiket használ, ezek nélkül
                nem tudjuk biztosítani az oldal működését. Kérjük, a továbblépéshez az
                alábbi gombra kattintva fogadja el ezeket.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-row class="w-100 pa-0 ma-0">
                    <v-col cols="12" md="6" class="d-flex justify-center align-center text-center">
                        <v-btn class="text-center" color="gray" variant="text" @click="leaveSite">
                            VISSZALÉPÉS
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex justify-center align-center text-center">
                        <v-btn class="text-center" color="green-darken-1" variant="text" @click="acceptConsent">
                            ELFOGADÁS ÉS TOVÁBBLÉPÉS
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { defineComponent } from "vue";
import { useConsentStore } from "../stores/ConsentStore.js";

export default defineComponent({
    name: `ConsentComponent`,
    data() {
        return {
            consentStore: useConsentStore(),
            show: null,
        };
    },
    mounted() {
        this.show = !this.consentStore.isCookieConsent;
    },
    methods: {
        leaveSite() {
            this.show = false;
            this.consentStore.setCookieConsent(false);
            window.history.back();
        },
        acceptConsent() {
            this.show = false;
            this.consentStore.setCookieConsent(true);
        },
    },
});
</script>
