import { defineStore } from "pinia";

const prevState = JSON.parse(window.localStorage.getItem("rszgv-pinia"));

export const useConsentStore = defineStore("consent", {
  state: () => {
    if (prevState && prevState.consents) {
      return prevState.consents;
    } else {
      return {
        cookie: null,
      };
    }
  },
  getters: {
    isCookieConsent: (state) => {
      if (state.cookie) {
        return state.cookie;
      }
      return false;
    },
  },
  actions: {
    setCookieConsent(value) {
      this.cookie = value;
    },
    resetConsentStore() {
      this.datamanagement = null;
    },
  },
});
