import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '../pages/HomePage.vue'
import LiteraturePage from '../pages/LiteraturePage.vue'
import InfoPage from '../pages/InfoPage.vue'
// import TasksPage from '../pages/TasksPage.vue'
// import TeamRegistration from '../pages/TeamRegistration.vue'
import { checkLogin } from '../services/userServices'

const  beforeEnter = async () => {
  return (await checkLogin()) ? true : {name: 'home'} 
}

const routes = [
  { path: '/:catchAll(.*)*', redirect: '/' },
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  // { path: '/tasks', name: 'tasks', component: TasksPage, beforeEnter},
  // { path: '/team', name: 'team', component: TeamRegistration, beforeEnter },
  { path: '/literature', name: 'literature', component: LiteraturePage, beforeEnter },
  { path: '/info', name: 'info', component: InfoPage },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})
